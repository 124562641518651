import axios from 'axios';
const TOKEN = '0f3f7c93cf3e68e7c3c54f0167c79e98f10936ed';

const fetch = axios.create({
  method: 'POST',
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': 'Token ' + TOKEN,
  },
});

export const country = (query, ...params) => {
  return fetch('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/country', {
    data: { query, ...params },
  }).then(({ data }) => data);
};

export const address = (query, params = {}) => {
  return fetch('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address', {
    data: { query, ...params },
  }).then(({ data }) => data);
};

export const inn = (query, params = {}) => {
  return fetch('https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party', {
    data: { query, ...params },
  }).then(({ data }) => data);
};

export const bank = (query, params = {}) => {
  return fetch('https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/bank', {
    data: { query, ...params },
  }).then(({ data }) => data);
};
