<template>
  <VTextField
      v-mask="mask"
      :label="label"
      :value="value"
      :rules="rules"
      :disabled="disabled"
      :readonly="readonly"
      outlined
      dense
      hideDetails="auto"
      autocomplete="chrome-off"
      @input="value => $emit('input', value)"
  />
</template>

<script>
export default {
  name: 'PhoneField',
  props: {
    label: { type: String },
    value: { type: String },
    rules: { type: Array, default: () => []},
    readonly: { type: Boolean },
    disabled: { type: Boolean },
  },
  computed: {
    mask() {
      if (!this.value) return '+7 (###) ### ## ##';
      if (/^\+7/.test(this.value)) return '+7 (###) ### ## ##';
      if (/^8/.test(this.value)) return '8 (###) ### ## ##';
      if (/^\+/.test(this.value)) return '+####################';
      return '#######################################';
    }
  }
}
</script>

<style module lang="scss"></style>
